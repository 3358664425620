 // eslint-disable-next-line import/no-anonymous-default-export
 export default {
    en: {
        translation: {
            "en": "ع",
        },
    },
    ar: {
        translation: {
            "en": "en",
            "B": "ا",
            "lueboard": "lueboard",
            "logo":"بدج",
            "Account settings": "اعدادت الحساب",
            "Add another account": "اضافه حساب جديد",
            'Profile':"الصفحة الشخصية",
            "My account" :"حسابي",
            "Settings":"الاعدادات",
            "Logout":"تسجيل الخروج",
            "add":"اضافة",
            "search":"بحث",
            "Required":"مطلوب",
            "Password is a required field":"كلمة المرور هي حقل مطلوب ",
            "Must be 15 characters or less":"يجب أن يكون عدد الأحرف 15 حرفًا أو أقل ",
            "Email is invalid":"البريد الاكتروني غير صحيح",
            "Email is required":"هذا الحقل مطلوب",
            "Hello!":"!مرحبا",
            "UserName":"اسم المستخدم",
            "Password":"كلمة السر",
            "FirstName":"الاسم الاول",
            "LastName":"الاسم الاخير",
            "Email":"البريد الاكتروني",
            "Login":"تسجيل دخول",
            "Registration":"تسجيل",
            " Loading...":"تحميل ...",
            "don't have an account?":"هل ليس لديك حساب ",
            "go to Register":"اذهب لتسجيل حساب جديد",
            "if you have account ?":"هل لديك حساب ؟",
            "go to login":"اذهب لتسجيل الدخول",
            "for technical support call us on":"للدعم الفني اتصل بنا علي",
            "All Rights Reserved by Smart Gate admin. Designed and Developed by":"جميع الحقوق محفوظة بواسطة إدارة سمارت جيت. ",
            // sidebar
            "Users":"المستخدمين",
            "Tables":"الجداول",
            "Reports":"التقارير",
            "post":"نشر",

        },
    },
};
